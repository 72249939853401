import React, { useEffect, useState } from 'react'
import metamask from '../../../assets/images/wallet/metamask.png';
import alpha from '../../../assets/images/wallet/alpha.png';
import binance from '../../../assets/images/wallet/binance.png';
import bitski from '../../../assets/images/wallet/bitski.png';
import coinbase from '../../../assets/images/wallet/coinbase.png';
import enjin from '../../../assets/images/wallet/enjin.png';
import formatic from '../../../assets/images/wallet/formatic.png';
import pionex from '../../../assets/images/wallet/pionex.png';
import torus from '../../../assets/images/wallet/torus.png';
import trustwallet from '../../../assets/images/wallet/trustwallet.png';
import connect from '../../../assets/images/wallet/wallet-connect.png';
import Navbar from '../../../components/navbar'
import FooterNew from '../../../components/footer-new';
import Switcher from '../../../components/switcher';
import HomeKBS from '../../../assets/images/royal-catering.jpg';
import image7 from '../../../assets/images/round1.jpg';
import Gallery1 from '../../../assets/images/wedding-r.jpg';
import Gallery2 from '../../../assets/images/bussiness-r.webp';
import Gallery3 from '../../../assets/images/private-r.jpeg';
import Gallery4 from '../../../assets/images/corporate-r.jpg';
import Gallery5 from '../../../assets/images/social-r.jpg';
import Gallery6 from '../../../assets/images/gallery6.jpg';
import Gallery7 from '../../../assets/images/birthday-r.jpg';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward,MdOutlineStarRate} from "../../../assets/icons/vander"
import {LuClock, MdKeyboardArrowLeft, MdKeyboardArrowRight,  LuCalendarDays} from "../../../assets/icons/vander"
import { blogData } from '../../../data/data';


const images = [
    { id: 1, src: Gallery1, name: 'Wedding',category: 'Images', },
    { id: 2, src: Gallery2, name: 'Business',category: 'Images', },
    { id: 3, src: Gallery3, name: 'Private',category: 'Images', },
    { id: 4, src: Gallery4, name: 'Corporate',category: 'Images', },
    { id: 5, src: Gallery5, name: 'Social',category: 'Images', },
    // { id: 6, src: Gallery6, name: 'Image 6',category: 'Images', },
    { id: 7, src: Gallery7, name: 'Birthday', category: 'Images', },
    // { id: 8, src: Gallery8, name: 'Image 8', category: 'Images', },
    // { id: 9, src: Gallery9, name: 'Image 9',category: 'Images', },
    // { id: 10, src:Gallery10, name: 'Image 10', category: 'Images', },
    // { id: 11, src: Gallery11, name: 'Image 11', category: 'Images', },
    // { id: 12, src: Gallery12, name: 'Image 12', category: 'Images', },
    // { id: 13, src: Gallery13, name: 'Image 13', category: 'Images', },
    // { id: 14, src: Gallery14, name: 'Image 14', category: 'Images', },
    // { id: 15, src: Gallery15, name: 'Image 15', category: 'Images', },
    // { id: 16, src: Gallery16, name: 'Image 16', category: 'Images', },
    // { id: 17, src: Gallery17, name: 'Image 17', category: 'Images', },
    // { id: 18, src: Gallery18, name: 'Image 18', category: 'Images', },
    // { id: 20, src: Gallery20, name: 'Image 20', category: 'Images', },
    // { id: 21, src: Gallery21, name: 'Image 21', category: 'Images', },
    // { id: 22, src: Gallery22, name: 'Image 22', category: 'Images', },
    // { id: 23, src: Gallery23, name: 'Image 23', category: 'Images', },
    // { id: 24, src: 'https://www.youtube.com/embed/C0DPdy98e4c', name: 'Image 11', category: 'Videos' },
    // { id: 25, src: Gallery25, name: 'Image 25', category: 'Images', },
    // { id: 26, src: Gallery26, name: 'Image 26', category: 'Images', },
    // { id: 27, src: Gallery27, name: 'Image 27', category: 'Images', },
    // { id: 28, src: Gallery28, name: 'Image 28', category: 'Images', },
    // { id: 29, src: Gallery29, name: 'Image 29', category: 'Images', },
    // { id: 30, src: Gallery30, name: 'Image 30', category: 'Images', },
    // { id: 31, src: Gallery31, name: 'Image 31', category: 'Images', },
    // { id: 32, src: Gallery32, name: 'Image 32', category: 'Images', },
];


export default function RoyalCateringServices() {

    const [lightboxMedia, setLightboxMedia] = useState({ type: '', src: '' });
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const showLightbox = (media) => {
        setLightboxMedia(media);
        setIsLightboxOpen(true);
        document.body.style.overflow = 'hidden';
    };
    

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        document.body.style.overflow = 'auto';
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const image7 = 'https://media.istockphoto.com/id/1366428092/photo/webinar-e-learning-skills-business-internet-technology-concepts-training-webinar-e-learning.jpg?s=612x612&w=0&k=20&c=mJeYiZyGQXLXXZ6AWKbLwQDiuLr-YLRGV4VjHKdX0pM='

    const filteredData = selectedCategory
        ? images.filter((item) => item.category === selectedCategory)
        : images;

 

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
   
        
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-369 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" >
                    {/* <img src={logo_white} width={120} height={120} alt="Image Description" class="mx-auto mt-6"/> */}
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}>ROYAL CATERING AND EVENTS</h4>
                    {/* <p className="max-w-xl mx-auto" style={{color:'#000'}}>Explore a marketplace where AI models are uniquely represented as NFTs for intuitive trading and creation.</p> */}
                    {/* <div className="mb-3">
                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc-5YtQHmJiCb_krw_WCMLirRqtDJ6jPxd7nI7KY7wYrMpPWw/viewform?usp=sf_link" target="_blank" className="btn text-white rounded-full me-2 mt-2" style={{cursor:'pointer',background:'#c82325',color:'#fff',border:'1px solid #c82325'}}>Apply Now</Link>
                    </div> */}
                </div>

                </div>
            </section>

        {/* <section className="relative md:py-24 py-16">
               <div className="container">
                 <div className="md:flex flex-wrap p-6 bg-white dark:bg-slate-900 shadow  rounded-md" style={{border:'0.5px solid #e11d23'}}>
                     <div className="md:w-1/2">
                         <div className="p-6 bg-white dark:bg-slate-900 ">
                         <h4 className="slider-head-3"style={{marginBottom:"50px",marginTop:"10px"}}>Our Vision</h4>
                             <h5 className="text-xl font-semibold mb-4 mt-8">Student Mentoring System & Motivation Classes</h5>
                             <p className="text-slate-401">To pursue service excellence by establishing highly acclaimed catering facilities and service that set the industry benchmark in the region while aiming to exceed culinary and hospitality expectations.</p>
                         </div>
                     </div>
                     <div className="md:w-1/2">
                         <div className="p-6 bg-white dark:bg-slate-900" style={{marginTop:'0px'}}>
                             <h4 className="slider-head-3"style={{marginBottom:"50px",marginTop:"10px"}}>Our Mission</h4>
                             <p className="text-slate-401">To bring to service a seasoned group of catering professional that will turn on an unrivaled customer service that will have our clients guests wanting more.To make your event wonderful and truly memorable is our only aim and business.This is because it is as important us as it is for you.Therefore,we take care of all details so you can simply enjoy yourself.</p>
                         </div>
                     </div>
                 </div>
               </div>
           </section> */}

            {/* <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}

            <section className="relative pt-28 md:pb-24 pb-16"style={{marginTop:"-140px",marginBottom:"10px"}}>
              <h4 className="slider-head-3">Royal Catering & Events</h4>
                <div className="container"style={{marginTop:"-60px",}}>
                <div className="lg:col-span-7 lg:ms-8">
                                  <p className="text-slate-401 mt-414"> 
Royal catering and events is a division of royal group which was founded by Sir.P.V.Hamsa about 50 years back.All along royal catering and events has innovated the kerala market with unique spins on classic dishes,event design and presentations.We excel in listening to our customer’s ideas and objectives and creating customised menus and event plans for executing even the most ambitious signature styles.Royal catering and events caters and plans events for all occasions,be they weddings,birthdays,anniversaries,or private dinner parties.We also manage exclusive events for corporates,company dinners,business lunches,corporate hospitality,product launch,and boardroom catering.</p>
                                    
                                </div>
                </div>
            </section>

            <section className="gallery" style={{ marginTop: '-60px' }}>
               <ul className="images1">
                   {filteredData.map((media, index) => (
                       <li className="img" key={index} style={{ position: 'relative' }}>
                               <div className="relative">
                                   <img src={media.src} alt={media.name} />
                                   <div className='absolute inset-0 flex items-center justify-center text-black hover-overlay  md:leading-snug leading-snug font-semibold'style={{fontSize:"200px"}}>
                                       <span>{media.name}</span>
                                   </div>
                               </div>
                       </li>
                   ))}
               </ul>
         </section>

            <section className="relative md:py-24 py-16"style={{marginTop:"-100px",display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                <div className="container"style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                    
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-[30px] ">
                        {
                            blogData.map((ele, index) => (
                                <Link to={`/${ele.route}`}>
                                <div key={index} className="group relative dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                    {ele.id !== 10 && ele.id !== 12 && ele.id !== 1 && ele.id !== 3 &&(<img style={{width:'100%'}} src={ele.image} alt="" />)}
                                    {ele.id === 10 && ele.id === 12 && ele.id === 1 && ele.id === 3 &&(<img style={{width:'100%'}} src={ele.image} alt="" />)}
                                </div>
                                </Link>
                                
                            ))
                        }
                    </div>
                    
                </div>
                {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav>
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                        <MdKeyboardArrowLeft className="text-[20px]"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">1</Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">2</Link>
                                </li>
                                <li>
                                    <Link to="/" aria-current="page" className="z-10 w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-violet-600 shadow-sm dark:shadow-gray-700">3</Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">4</Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                        <MdKeyboardArrowRight className="text-[20px]"/>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> */}

            </section >
            <FooterNew />
            {/* <Switcher /> */}
        </>
    )
}
