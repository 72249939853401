import React, { useState, useEffect } from 'react';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { MdOutlineArrowBack, MdOutlineArrowBackIos, MdOutlineArrowForward, MdOutlineArrowForwardIos } from 'react-icons/md';
import testimonial1 from '../../assets/images/testimonial1.png'
import testimonial2 from '../../assets/images/testimonial2.png'
import testimonial3 from '../../assets/images/testimonial3.png'
import testimonial4 from '../../assets/images/testimonial4.png'
import testimonial5 from '../../assets/images/testimonial5.jpg'

export default function Testimonial () {
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = [
    {
      imgSrc: testimonial1,
      name1: "Lal Jose",
      name2: "Film Director",
      text: "I loved Royal catering and all of the wonderful staff there! You truly made our daughters wedding spectacular! I would recommend you to anyone who wants class, sophistication and style as well as a delicious dinner or party!"
    },
    {
      imgSrc: testimonial2,
      name1: "Anil Radhakrishnan menon",
      name2: "Film Director",
      text: "I had a chance to taste their Thalassery biriyani from an event, great food, exceptional service and attention to detail. Most importantly - really, really nice people who are so easy to work with! Many thanks to Anees and the rest of the Royal team."
    },
    {
      imgSrc: testimonial3,
      name1: "Harisree Ashokan",
      name2: "Film Actor ",
      text: "If you are looking to work with a caring committed team we highly recommend Royal Caterings. They gave me  a truly unique and personalized catering experience full of delicious food, gorgeous flowers, and a stress free day."
    },
    {
      imgSrc: testimonial4,
      name1: "Jayasuriya",
      name2: "Actor",
      text: "I just wanted to drop you a line to say thanks to everyone at The Royal Catering & Events Company for making my party perfect!  The team could not have been more helpful and the food was amazing!  Thank you for coming up with such excellent suggestions and ensuring everything was planned so well.  Over the years, Mr Anees & his team have never let me down and this party was another triumph so I am a very happy customer"
    },
    // {
    //   imgSrc: testimonial5,
    //   name1: "George",
    //   name2: "Co-Founder",
    //   text: "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
    // },
  ];

 
  const handleLeftArrowClick = () => {
    setCurrentSlide(currentSlide === 0 ? testimonials.length - 1 : currentSlide - 1);
  };

 
  const handleRightArrowClick = () => {
    setCurrentSlide(currentSlide === testimonials.length - 1 ? 0 : currentSlide + 1);
  };

 
  useEffect(() => {
    const slideTimer = setInterval(() => {
      setCurrentSlide(currentSlide === testimonials.length - 1 ? 0 : currentSlide + 1);
    }, 4500);

    return () => clearInterval(slideTimer);
  }, [currentSlide, testimonials.length]);

  return (
      <section id="testim" className="testim">
       
        <div className="testim-cover">
          <div className="wrap">
          
            <div id="testim-content" className="cont">
              {testimonials.map((testimonial, index) => (
                <div key={index} className={index === currentSlide ? "active" : ""}>
                  <div className="img"><img src={testimonial.imgSrc} alt="" /></div>
                  <p style={{marginTop:'20px',fontStyle:'italic'}}>"{testimonial.text}"</p>
                  <h2 className='md:text-2xl text-xl font-semibold' style={{marginTop:'25px',marginBottom:'-15px'}}>{testimonial.name1}</h2>
                  <h2 className='text-black'>{testimonial.name2}</h2>
                </div>
              ))}
            </div>

          
            <MdOutlineArrowForwardIos id="right-arrow" className="arrow right fa fa-chevron-right" onClick={handleRightArrowClick}/>
            <MdOutlineArrowBackIos id="left-arrow" className="arrow left fa fa-chevron-left" onClick={handleLeftArrowClick}/>

          
            <ul id="testim-dots" className="dots">
              {testimonials.map((_, index) => (
                <li key={index} className={index === currentSlide ? "dot active" : "dot"}></li>
              ))}
            </ul>
          </div>
        </div>
      </section>
  );
};
