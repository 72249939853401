import React from 'react'
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'

import contact from '../../../assets/images/contact.svg';
import Switcher from '../../../components/switcher';
import FooterNew from '../../../components/footer-new';
import HomeKBS from '../../../assets/images/royal-background5.jpg';
import { Link } from 'react-router-dom';
import {FaRegEnvelope, BsTelephone, FiMapPin, MdOutlineStarRate, CiLocationOn, MdEmail, MdFacebook, MdPhoneAndroid, MdYoutubeSearchedFor, BsYoutube} from "../../../assets/icons/vander"
import { Facebook, Phone, Youtube } from 'feather-icons-react/build/IconComponents';

export default function Contact() {
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-369 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" >
                    {/* <img src={logo_white} width={120} height={120} alt="Image Description" class="mx-auto mt-6"/> */}
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}>CONTACT US</h4>
                    {/* <p className="max-w-xl mx-auto" style={{color:'#000'}}>Explore a marketplace where AI models are uniquely represented as NFTs for intuitive trading and creation.</p> */}
                    {/* <div className="mb-3">
                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc-5YtQHmJiCb_krw_WCMLirRqtDJ6jPxd7nI7KY7wYrMpPWw/viewform?usp=sf_link" target="_blank" className="btn text-white rounded-full me-2 mt-2" style={{cursor:'pointer',background:'#c82325',color:'#fff',border:'1px solid #c82325'}}>Apply Now</Link>
                    </div> */}
                </div>

                </div>
            </section>
            {/* <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}

            <section className="relative" style={{marginBottom:'300px',marginTop:'80px'}}>
                <div className="container">
                <h4 className="slider-head-5"style={{marginBottom:'75px'}}>Keep in Touch</h4>
                
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <BsTelephone className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="text-slate-401 font-semibold">Phone</h5>
                                {/* <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p> */}

                                <div className="mt-5">
                                    <Link to="tel:+91 8943100500" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">+91 8943100500</Link><br />
                                    <Link to="tel:+91 7034100500" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">+91 7034100500</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <FaRegEnvelope className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="text-slate-401 font-semibold">Email</h5>
                                {/* <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p> */}

                                <div className="mt-5">
                                    <Link to="mailto:info@royalcatering.in" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">info@royalcatering.in</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <FiMapPin className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="text-slate-401 font-semibold">Location</h5>
                                <p className="text-slate-401 mt-3">Manissery PO,Palakkad – Ponnani Rd,Kanniyampuram,<br /> Ottapalam, Kerala 679521</p>
                                                 
                                <div className="mt-5">
                                    <Link to="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7838.9312578149775!2d76.350869!3d10.775605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7dbf01521ac3f%3A0x383d9e29ee048934!2sRoyal%20Catering!5e0!3m2!1sen!2sus!4v1714979627965!5m2!1sen!2sus"
                                        data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">View on Google map</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]"style={{marginTop:'-100px'}}>
                        <div className="lg:col-span-7 md:col-span-6 order-1 md:order-2">
                        <div className="lg:me-5">
                                <div className="md:flex flex-wrap p-6 shadow rounded-md" style={{border:'0.5px solid #e11d23'}}>
                                    

                                   
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid  lg:gap-6">
                                            <div className="lg:col-span-6 mb-5"style={{marginBottom:"-500px"}}>
                                              <h4 className="slider-head-5"style={{marginBottom:"40px",marginTop:"25px",textAlign:"center"}}>Quick Contact</h4>
                                              <ul className="list-none text-slate-401 mt-3" style={{marginBottom:"-50px"}} >
                                                 <li className="flex items-center mt-2"><MdPhoneAndroid className="text-base align-middle me-2" style={{color:'#e11d23',fontSize:'1.5rem'}}/> Ottapalam:	8943100500 | 7034100500</li>
                                                 <li className="flex items-center mt-2"><MdPhoneAndroid className=" text-base align-middle me-2" style={{color:'#e11d23',fontSize:'1.5rem'}}/>Cochin:	9118100500 | 7068100500</li>
                                                 <li className="flex items-center mt-2"><MdEmail className=" text-base align-middle me-2" style={{color:'#e11d23',fontSize:'1.5rem'}}/>info@royalcatering.in</li>
                                                 <li className="flex items-center mt-2"><MdEmail className=" text-base align-middle me-2" style={{color:'#e11d23',fontSize:'1.5rem'}}/>cochin@royalcatering.in</li>
                                                 <li className="flex items-center mt-2"><MdFacebook className=" text-base align-middle me-2" style={{color:'#e11d23',fontSize:'1.5rem'}}/> royalcatering</li>
                                                 <li className="flex items-center mt-2"><BsYoutube className=" text-base align-middle me-2" style={{color:'#e11d23',fontSize:'1.5rem'}}/>royalcateringotp</li>
                                             </ul>
                                                {/* <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                    <input id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Name :" />
                                                </div> */}
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                {/* <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                    <input id="email" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Email :" />
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            {/* <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                    <input id="subject" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Subject :" />
                                                </div>
                                            </div> */}

                                            <div className="mb-5">
                                                {/* <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                    <textarea id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Message :"></textarea>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <button type="submit" id="submit" name="send" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{display:"flex", alignItems: "center" }}>Submit</button> */}
                                    
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-5 md:col-span-6 mt-8 md:mt-0 order-2 md:order-1">
                            <div className="lg:me-5">
                                <div className="md:flex flex-wrap p-6 bg-white dark:bg-slate-900 shadow  rounded-md" style={{border:'0.5px solid #e11d23'}}>
                                    

                                    <form method="post" name="myForm" id="myForm">
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    {/* <label htmlFor="name" className="font-semibold">Your Name:</label> */}
                                                    <input id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{color:'#000'}} placeholder="Name :" />
                                                </div>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    {/* <label htmlFor="email" className="font-semibold">Your Email:</label> */}
                                                    <input id="email" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{color:'#000'}} placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            {/* <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                    <input id="subject" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Subject :" />
                                                </div>
                                            </div> */}

                                            <div className="mb-5">
                                                <div className="text-start">
                                                    {/* <label htmlFor="comments" className="font-semibold">Your Comment:</label> */}
                                                    <textarea id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent  rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{color:'#000'}} placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{display:"flex", alignItems: "center" }}>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid relative" style={{marginTop:'-200px'}}>
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7838.9312578149775!2d76.350869!3d10.775605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7dbf01521ac3f%3A0x383d9e29ee048934!2sRoyal%20Catering!5e0!3m2!1sen!2sus!4v1714979627965!5m2!1sen!2sus" style={{ border: 0 }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            <FooterNew  />
            {/* <Switcher /> */}
        </>
    )
}
