import React from 'react'
import logo_white from '../assets/images/royal-footer.png';
import app from '../assets/images/app.png';
import playstore from '../assets/images/playstore.png';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'react-feather';
import PhoneCall from 'feather-icons-react/build/IconComponents/PhoneCall';
import {AiOutlineShoppingCart, MdKeyboardArrowRight, FaRegEnvelope, BsPencil, FiDribbble, BiLogoBehance, FaLinkedin, LiaFacebookF, AiOutlineInstagram, AiOutlineTwitter, GoLocation, TfiEmail, MdEmail } from "../assets/icons/vander"

export default function FooterNew() {
    return (
        <>
        <footer className='foot'>
      <div class="main-content">
        <div class="left box">
          {/* <h2>About us</h2> */}
              <Link s className="text-[22px] focus:outline-none">
                  <img src={logo_white} width={160} height={160} alt="" />
              </Link>
          <div class="content">
              
            <p className='text-slate-400' style={{marginTop:'20px'}}>We, at royal catering services, seek to provide the best of service that can be made available,thanks to the hard work of our employees and the faith of our clients.</p>
            <div class="social">
              <a ><span class="ic--outline-facebook"></span></a>
              <a ><span class="ri--twitter-x-fill"></span></a>
              <a ><span class="ri--instagram-line"></span></a>
            </div>
            <ul className="list-none footer-list mt-6">
                 <h5 className="tracking-[1px] text-lg "style={{color:"#ed2424"}}>For Enquiries :</h5>
                   <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>KSA           : +96656 6633951</Link></li>
                   <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>UAE          : +971 50533 2249</Link></li>
               </ul>
          </div>
        </div>
        <div class="center box">
          <h2>Head Office–Ottapalam </h2>
          <div class="content">
            {/* <div class="place">
              <span class="fas fa-map-marker-alt"></span>
              <span class="text">Surat, Gujarat</span>
            </div> */}
               <ul className="list-none footer-list mt-6">
                   <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}><GoLocation className="me-2 text-lg"/> Manissery PO,  </Link></li>
                   <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Palakkad – Ponnani Rd,</Link></li>
                   <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Kanniyampuram, </Link></li>
                   <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Ottapalam, Kerala 679521</Link></li>
                   <li className="mt-[10px]"><Link to="tel:+91 8943 100 500 " className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 8943100500 </Link></li>
                   <li className="mt-[10px]"><Link to="tel:+91 7034100500 " className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 7034100500</Link></li>
               </ul>
               
          </div>
        </div>
        <div class="center box">
          <h2>Branch office – Cochin </h2>
          <div class="content">
            {/* <div class="place">
              <span class="fas fa-map-marker-alt"></span>
              <span class="text">Surat, Gujarat</span>
            </div> */}
                <ul className="list-none footer-list mt-6">
                  <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}><GoLocation className="me-2 text-lg"/> Royal Catering Services </Link></li>
                    <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>IV/12-B, Kanamburam, Karukulam,Vilangu P.O,</Link></li>
                    <li><Link to="#" className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Pin – 683561 Kizhakkambalam Ernakulam District</Link></li>
                    <li className="mt-[10px]"><Link to="tel:+91 8943 100 500 " className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 8943100500 </Link></li>
                   <li className="mt-[10px]"><Link to="tel:+91 7034100500 " className="text-[16px] text-slate-400 hover:text-slate-400 duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 7034100500</Link></li>
                </ul>
          </div>
        </div>
        <div class="right box">
          <h2>Menu</h2>
          <div class="content">
               <ul className="list-none footer-list mt-6">
                   <li className="mt-[10px]"><Link to="/" className="btn btn-link text-slate-400 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">Home</Link></li>
                   <li className="mt-[10px]"><Link to="/about-us" className="btn btn-link text-slate-400 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center"> About us</Link></li>
                   {/* <li className="mt-[10px]"><Link to="/comingsoon" className="btn btn-link text-slate-400 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center"> Portfolio</Link></li> */}
                   <li className="mt-[10px]"><Link to="/comingsoon" className="btn btn-link text-slate-400 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center"> Gallery</Link></li>
                   {/* <li className="mt-[10px]"><Link to="/comingsoon" className="btn btn-link text-slate-400 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center"> Videos</Link></li> */}
                   <li className="mt-[10px]"><Link to="/contact-us" className="btn btn-link text-slate-400 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center"> Contact us</Link></li>   
               </ul>
          </div>
        </div>
      </div>
      <div class="bottom">
        <center>
          <span class="credit">Created By <a href="#">skyniche Production</a> | </span>
          <span class="far fa-copyright"></span><span> 2024 All rights reserved.</span>
        </center>
      </div>
</footer>
    </>
    )
}