import React from 'react'
import logo_white from '../assets/images/logo-white.png';
import app from '../assets/images/app.png';
import playstore from '../assets/images/playstore.png';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'react-feather';
import PhoneCall from 'feather-icons-react/build/IconComponents/PhoneCall';
import {AiOutlineShoppingCart, MdKeyboardArrowRight, FaRegEnvelope, BsPencil, FiDribbble, BiLogoBehance, FaLinkedin, LiaFacebookF, AiOutlineInstagram, AiOutlineTwitter, GoLocation, TfiEmail, MdEmail } from "../assets/icons/vander"

export default function Footer() {
    return (
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200 mt-24">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative py-16">
                        <div className="relative w-full">
                        <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] ">
                                <div className="lg:col-span-4 md:col-span-12">
                                    <Link to="#" className="text-[22px] focus:outline-none">
                                        <img src={logo_white} width={80} height={80} alt="" />
                                    </Link>
                                    <p className="mt-6 text-white">We, at royal catering services, seek to provide the best of service that can be made available,thanks to the hard work of our employees and the faith of our clients.</p> 
                                    <ul className="list-none md:text-start mt-5">
                                        <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 dark:hover:text-violet-600 "><LiaFacebookF  style={{color:'white'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><AiOutlineInstagram  style={{color:'white'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><AiOutlineTwitter  style={{color:'white'}} className='text-base'/></Link></li>
                                    </ul>
                                </div>
                                <div className="lg:col-span-3 md:col-span-12">
                                    <h5 className="tracking-[1px] text-lg text-white font-bold">WHERE WE ARE</h5>
                                    
                                    <ul className="list-none footer-list mt-6">
                                      <h5 className="tracking-[1px] text-lg "style={{color:"#ed2424"}}>Head Office–Ottapalam :</h5>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}><GoLocation className="me-2 text-lg"/> Manissery PO,  </Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Palakkad – Ponnani Rd,</Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Kanniyampuram, </Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Ottapalam, Kerala 679521</Link></li>
                                        <li className="mt-[10px]"><Link to="tel:+91 8943 100 500 " className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 8943100500 <br /> +91 7034100500</Link></li>
                                    </ul>
                                    
                                    <ul className="list-none footer-list mt-6">
                                      <h5 className="tracking-[1px] text-lg "style={{color:"#ed2424"}}>For Enquiries :</h5>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>KSA           : +96656 6633951</Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>UAE          : +971 50533 2249</Link></li>
                                    </ul>
                                   

                                </div>

                                <div className="lg:col-span-2 md:col-span-4">
                                    {/* <h5 className="tracking-[1px] text-lg text-white font-bold">MENUS</h5> */}
                                    <ul className="list-none footer-list mt-6">
                                      <h5 className="tracking-[1px] text-lg "style={{color:"#ed2424"}}>Branch office – Cochin :</h5>
                                      <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}><GoLocation className="me-2 text-lg"/> Royal Catering Services </Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>IV/12-B, Kanamburam, Karukulam,Vilangu P.O,</Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>Pin – 683561 Kizhakkambalam Ernakulam District</Link></li>
                                        <li className="mt-[10px]"><Link to="tel:+91 8943 100 500 " className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 8943100500 <br /> +91 7034100500</Link></li>
                                    </ul>
                                </div>
                                <div className="lg:col-span-2 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-white font-bold">MENUS</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center">Home</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> About us</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Portfolio</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Gallery</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Videos</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Contact us</Link></li>   
                                    </ul>
                                </div>

                                {/* <div className="lg:col-span-2 md:col-span-4" >
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold"><span className=" bg-clip-text">OUR LOCATIONS</span></h5>
                                    <ul className="list-none footer-list mt-6">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250836.41218916932!2d76.37322469633914!3d10.786866219520117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86e084d00b6b5%3A0xf74e6bc864017d8e!2sKBS%20Academy%20Palakkad%20Town%20Campus!5e0!3m2!1sen!2sin!4v1710735428272!5m2!1sen!2sin" width="300" height="170" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </ul>
                                </div> */}

                                {/* <div className="lg:col-span-3 md:col-span-4" style={{marginLeft:'0px'}}>
                                <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-white font-bold">Legal</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Privacy</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Terms</Link></li>
                                    </ul>
                                </div>
                                </div> */}
                            </div>

                            {/* <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-24">
                                <div className="lg:col-span-4 md:col-span-12">
                                    <Link to="#" className="text-[22px] focus:outline-none">
                                        <img src={logo_white} alt="" />
                                    </Link>
                                    <p className="mt-6 text-gray-300">Buy, sell and discover exclusive digital assets by the top artists of NFTs world.</p>

                                </div>

                                <div className="lg:col-span-2 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Giglink</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="/explore-one" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Explore Item</Link></li>
                                        <li className="mt-[10px]"><Link to="/auction" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Live Auction</Link></li>
                                        <li className="mt-[10px]"><Link to="/activity" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Activities</Link></li>
                                        <li className="mt-[10px]"><Link to="/wallet" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Wallets</Link></li>
                                        <li className="mt-[10px]"><Link to="/creators" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Creators</Link></li>
                                    </ul>
                                </div>

                                <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Usefull Links</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="/aboutus" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> About Us</Link></li>
                                        <li className="mt-[10px]"><Link to="/blogs" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Blog & News</Link></li>
                                        <li className="mt-[10px]"><Link to="/terms" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Terms & Condition</Link></li>
                                        <li className="mt-[10px]"><Link to="/privacy" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Privacy policy</Link></li>
                                        <li className="mt-[10px]"><Link to="/login" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Login</Link></li>
                                        <li className="mt-[10px]"><Link to="/contact" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="me-1 text-lg"/> Contact Us</Link></li>
                                    </ul>
                                </div>

                                <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Download the Giglink app</h5>

                                    <ul className="list-none mt-6">
                                        <li className="inline"><img src={app} className="h-9 inline-block" alt="" /></li>
                                        <li className="inline"><img src={playstore} className="h-9 inline-block" alt="" /></li>
                                    </ul>

                                    <div className="mt-6">
                                        <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Contact Details</h5>

                                        <div className="flex mt-6">
                                            <Mail className="w-5 h-5 text-violet-600 me-3 mt-1"></Mail>
                                            <div className="">
                                                <Link to="mailto:contact@example.com" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out">contact@example.com</Link>
                                            </div>
                                        </div>

                                        <div className="flex mt-6">
                                            <Phone className="w-5 h-5 text-violet-600 me-3 mt-1"></Phone>
                                            <div className="">
                                                <Link to="tel:+152534-468-854" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out">+152 534-468-854</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-gray-300">© {(new Date().getFullYear())}{" "} Giglink. Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                        </div>

                        <ul className="list-none md:text-end text-center">
                            <li className="inline space-x-1"><Link to="https://1.envato.market/giglink-react" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineShoppingCart className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><FiDribbble className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><BiLogoBehance className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><FaLinkedin className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><LiaFacebookF className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineInstagram className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://twitter.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineTwitter className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="mailto:support@shreethemes.in" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><FaRegEnvelope className="align-middle"/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
