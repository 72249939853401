import React, { useEffect } from 'react'
import metamask from '../../../assets/images/wallet/metamask.png';
import alpha from '../../../assets/images/wallet/alpha.png';
import binance from '../../../assets/images/wallet/binance.png';
import bitski from '../../../assets/images/wallet/bitski.png';
import coinbase from '../../../assets/images/wallet/coinbase.png';
import enjin from '../../../assets/images/wallet/enjin.png';
import formatic from '../../../assets/images/wallet/formatic.png';
import pionex from '../../../assets/images/wallet/pionex.png';
import torus from '../../../assets/images/wallet/torus.png';
import trustwallet from '../../../assets/images/wallet/trustwallet.png';
import connect from '../../../assets/images/wallet/wallet-connect.png';
import Navbar from '../../../components/navbar'
import FooterNew from '../../../components/footer-new';
import Switcher from '../../../components/switcher';
import HomeKBS from '../../../assets/images/royal-hypermarket.jpg';
import image7 from '../../../assets/images/round7.jpg';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward,MdOutlineStarRate} from "../../../assets/icons/vander"
import {LuClock, MdKeyboardArrowLeft, MdKeyboardArrowRight,  LuCalendarDays} from "../../../assets/icons/vander"
import { blogData } from '../../../data/data';


export default function RoyalHypermarket() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
   
        
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-369 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" >
                    {/* <img src={logo_white} width={120} height={120} alt="Image Description" class="mx-auto mt-6"/> */}
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}>ROYAL HYPERMARKET</h4>
                    {/* <p className="max-w-xl mx-auto" style={{color:'#000'}}>Explore a marketplace where AI models are uniquely represented as NFTs for intuitive trading and creation.</p> */}
                    {/* <div className="mb-3">
                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc-5YtQHmJiCb_krw_WCMLirRqtDJ6jPxd7nI7KY7wYrMpPWw/viewform?usp=sf_link" target="_blank" className="btn text-white rounded-full me-2 mt-2" style={{cursor:'pointer',background:'#c82325',color:'#fff',border:'1px solid #c82325'}}>Apply Now</Link>
                    </div> */}
                </div>

                </div>
            </section>

        {/* <section className="relative md:py-24 py-16">
               <div className="container">
                 <div className="md:flex flex-wrap p-6 bg-white dark:bg-slate-900 shadow  rounded-md" style={{border:'0.5px solid #e11d23'}}>
                     <div className="md:w-1/2">
                         <div className="p-6 bg-white dark:bg-slate-900 ">
                         <h4 className="slider-head-3"style={{marginBottom:"50px",marginTop:"10px"}}>Our Vision</h4>
                             <h5 className="text-xl font-semibold mb-4 mt-8">Student Mentoring System & Motivation Classes</h5>
                             <p className="text-slate-401">To pursue service excellence by establishing highly acclaimed catering facilities and service that set the industry benchmark in the region while aiming to exceed culinary and hospitality expectations.</p>
                         </div>
                     </div>
                     <div className="md:w-1/2">
                         <div className="p-6 bg-white dark:bg-slate-900" style={{marginTop:'0px'}}>
                             <h4 className="slider-head-3"style={{marginBottom:"50px",marginTop:"10px"}}>Our Mission</h4>
                             <p className="text-slate-401">To bring to service a seasoned group of catering professional that will turn on an unrivaled customer service that will have our clients guests wanting more.To make your event wonderful and truly memorable is our only aim and business.This is because it is as important us as it is for you.Therefore,we take care of all details so you can simply enjoy yourself.</p>
                         </div>
                     </div>
                 </div>
               </div>
           </section> */}

            {/* <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}

            <section className="relative pt-28 md:pb-24 pb-16"style={{marginTop:"-140px",marginBottom:"10px"}}>
              <h4 className="slider-head-36">Royal Hypermarket</h4>
                <div className="container"style={{marginTop:"-30px",}}>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5">
                            <img src={image7} style={{width:'100%',height:'100%',objectFit:'cover'}} alt="" />
                            {/* <img src={creater?.image ? creater?.image : image} className="rounded-md shadow dark:shadow-gray-700" style={{width:'372px',height:'372px'}} alt="" /> */}

                            {/* <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                                <div>
                                    <span className="font-medium text-slate-400 block mb-1">Contract Address</span>
                                    <Link to="#" className="font-medium text-violet-600 underline block">1fsvtgju51ntgeryimghf6ty7o9n3r3er246</Link>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Token ID</span>
                                    <span className="font-medium block">458342529342930944</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Blockchain</span>
                                    <span className="font-medium block">ETH</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Deposit & Withdraw</span>
                                    <span className="font-medium block">Unsupported</span>
                                </div>
                            </div> */}
                        </div>

                        <div className="lg:col-span-7 lg:ms-8">
                            {/* <span className="font-medium text-slate-400 block mt-2">From this collection: <Link to="/creator-profile" className="text-violet-600">{creater?.subtext ? creater?.subtext : "@FunnyGuy"}</Link></span> */}

                            
                            {/* <h5 className="text-xl font-semibold mb-4 mt-8">Restrictions :</h5>
                                <p className="text-slate-400">You are specifically restricted from all of the following :</p> */}
                                <div className="lg:col-span-7 lg:ms-8">
                                  <p className="text-slate-401 mt-414"> Royal Hypermarket is a dynamic retail company that is extremely adept at creating effective solutions for our valued partners and custromers. We have experienced extraordinary growth, while maintaining a high level of professionalism and customer service. Our passion, integrity and determination allow us to build strong, long-lasting relationships with our suppliers and customers.</p>
                                    
                                </div>

                            {/* <div className="mt-4">
                                <span className="text-lg font-medium text-slate-400 block">Market Price</span>
                                <span className="tmd:text-2xl text-xl font-semibold block mt-2"><i className="mdi mdi-ethereum"></i> 3.5 ETH = $ 4,659.75</span>
                            </div> */}

                            {/* <div className="mt-6">
                                <Link to="#" onClick={()=> setPlaceBid(!placeBid)} className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i className="mdi mdi-gavel"></i> Bid Now</Link>
                                <Link to="#" onClick={() => setBuyNow(!buyNow)} className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white ms-1"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                            </div> */}

                            {/* <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                <div className="md:w-1/2">
                                    <div className="flex items-center">
                                        <div className="relative inline-block">
                                            <img src={creater?.avatar ? creater?.avatar : image1} className="h-16 rounded-md" alt="" />
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                        </div>

                                        <div className="ms-3">
                                            <Link to="/creator-profile" className="font-semibold block hover:text-violet-600">{creater?.name ? creater?.name :"Michael Williams"}</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Creator</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="md:w-1/2 md:mt-0 mt-4">
                                    <div className="flex items-center">
                                        <div className="relative inline-block">
                                            <img src={creater?.avatar ? creater?.avatar : image1} className="h-16 rounded-md" alt="" />
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                        </div>

                                        <div className="ms-3">
                                            <Link to="/creator-profile" className="font-semibold block hover:text-violet-600">{creater?.name ? creater?.name :"Michael Williams"}</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Owner</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16"style={{marginTop:"-100px",display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                <div className="container"style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                    
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-[30px] ">
                        {
                            blogData.map((ele, index) => (
                                <Link to={`/${ele.route}`}>
                                <div key={index} className="group relative dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                    {ele.id !== 10 && ele.id !== 12 && ele.id !== 1 && ele.id !== 3 &&(<img style={{width:'100%'}} src={ele.image} alt="" />)}
                                    {ele.id === 10 && ele.id === 12 && ele.id === 1 && ele.id === 3 &&(<img style={{width:'100%'}} src={ele.image} alt="" />)}
                                </div>
                                </Link>
                                
                            ))
                        }
                    </div>
                    
                </div>
                {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav>
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                        <MdKeyboardArrowLeft className="text-[20px]"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">1</Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">2</Link>
                                </li>
                                <li>
                                    <Link to="/" aria-current="page" className="z-10 w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-violet-600 shadow-sm dark:shadow-gray-700">3</Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">4</Link>
                                </li>
                                <li>
                                    <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                        <MdKeyboardArrowRight className="text-[20px]"/>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> */}

            </section >
            <FooterNew />
            {/* <Switcher /> */}
        </>
    )
}
