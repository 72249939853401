import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import HomeKBS from '../../../assets/images/background-gallery.jpg';
import general1 from '../../../assets/images/royal-general1.jpg';
import general2 from '../../../assets/images/royal-general2.jpg';
import general3 from '../../../assets/images/royal-general3.jpg';
import general4 from '../../../assets/images/royal-general4.jpg';
import general5 from '../../../assets/images/royal-general5.jpg';
import general6 from '../../../assets/images/royal-general6.jpg';
import general7 from '../../../assets/images/royal-general7.jpg';
import general8 from '../../../assets/images/royal-general8.jpg';
import FooterNew from '../../../components/footer-new';
import Navbar from '../../../components/navbar';
import ArjunAshoken from './gallery-pages/arjun-ashoken';
import Jayasurya from './gallery-pages/jayasurya';
import Stories from './gallery-pages/stories';
import { MdOutlineArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';

function ThumbnailSlider({ onSelect }) {
  const images = [
    { id: 1, src: general1, name: 'general1', category: 'Images' },
    { id: 2, src: general2, name: 'general2', category: 'Images' },
    { id: 3, src: general3, name: 'general3', category: 'Images' },
    { id: 4, src: general4, name: 'general4', category: 'Images' },
    { id: 5, src: general5, name: 'general5', category: 'Images' },
    { id: 6, src: general6, name: 'general6', category: 'Images' },
    { id: 7, src: general7, name: 'general7', category: 'Images' },
    { id: 8, src: general8, name: 'general8', category: 'Images' },
  ];

  return (
    <div id="thumbnail_slider">
      <Splide
        options={{
          rewind: true,
          fixedWidth: 100,
          fixedHeight: 64,
          isNavigation: true,
          gap: 10,
          focus: 'center',
          pagination: false,
          cover: true,
          breakpoints: {
            600: {
              fixedWidth: 66,
              fixedHeight: 40,
            },
          },
        }}
      >
        {images.map((item, index) => (
          <SplideSlide key={index}>
            <img
              src={item.src}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => onSelect(item.src)}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

function PrimarySlider({ selectedImage }) {
  const images = [
    { id: 1, src: general1, name: 'general1', category: 'Images' },
    { id: 2, src: general2, name: 'general2', category: 'Images' },
    { id: 3, src: general3, name: 'general3', category: 'Images' },
    { id: 4, src: general4, name: 'general4', category: 'Images' },
    { id: 5, src: general5, name: 'general5', category: 'Images' },
    { id: 6, src: general6, name: 'general6', category: 'Images' },
    { id: 7, src: general7, name: 'general7', category: 'Images' },
    { id: 8, src: general8, name: 'general8', category: 'Images' },
  ];

  return (
    <div id="primary_slider">
      <Splide
        options={{
          type: 'fade',
          heightRatio: 0.5,
          pagination: false,
          arrows: false,
          cover: true,
        }}
      >
        {images.map((item, index) => (
          <SplideSlide key={index}>
            <img
              src={item.src}
              alt={`Image ${index + 1}`}
              style={{ display: selectedImage === item.src ? 'block' : 'none' }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default function Gallery() {
  const [selectedImage, setSelectedImage] = useState(general1); // Initial selected image

  const handleImageSelect = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-369 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center" >
            <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}>GALLERY</h4>
          </div>
        </div>
      </section>
      <div className="thumbnail_slider">
        <h2>Thumbnails Image Slider</h2>
        <PrimarySlider selectedImage={selectedImage} />
        <ThumbnailSlider onSelect={handleImageSelect} />
        <button type="submit" id="submit" name="send"><Link to="/general" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{display:"flex", alignItems: "center" }}>View More<MdOutlineArrowForward className="ms-1"/></Link></button>
      </div>
      <ArjunAshoken/>
      <Jayasurya/>
      <Stories/>
      <FooterNew />
    </>
  );
}
